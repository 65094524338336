.animate-out {
    animation-name: slide-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.animate-in {
    animation-name: slide-in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes slide-out {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.new-section {
    background-color: #ffffcc;
    transition: all .2s;
}