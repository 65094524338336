.editor .ql-container {
    background: transparent;
    border: none;
}

.editor .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    background: red; /* Set the desired background color for the toolbar */
    z-index: 1; /* Ensure the toolbar appears above the content */
}

.editor .ql-snow.ql-toolbar {
    display: block;
    background: transparent;
    border: none;
}

.editor .ql-container.ql-snow {
    border: none;
}

.editor .ql-bubble .ql-editor {
    border: none;
}

.editor .ql-editor {
    min-height: 18em;
    border: none;
    max-height: 360px;
    overflow-y: auto;
}

.editor .toolbar-container {
    position: relative;
}

.toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* tiptap editor */
.ProseMirror { 
    outline: none;
    margin: 20px 0; 
}

.editor-container { 
    margin: 20px 15px;
}

.editor-container .editor-menu { 
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.editor-container .editor-menu button { 
    border-radius: 3px;
}

.editor-container .editor-menu svg { 
    cursor: pointer; 
    font-size: 17px;
}

.editor-container .editor-menu .heading { 
    font-weight: 800;
}

.is-active { 
    background-color: #eee;
    padding: 5px;
}
.heading { 
    cursor: pointer; 
}